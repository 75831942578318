import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import SEO from 'components/seo'
import { composeClasses, jsonId } from 'utils'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { isMobile } from 'react-device-detect'
import LinkIcon from 'images/link-icon.svg'
import parse from 'html-react-parser'
import checkMark from 'images/check-mark-alt.svg'
import CarouselComponent from 'components/carousel'
import CaretUp from '../images/career/arrow-up.svg'
import CaretDown from '../images/career/arrow-down.svg'
import SocialLinks from 'components/socialLinks'
import quoteLeft from 'images/quote-left.svg'
import HelmetExport from 'react-helmet'
import {
  workForUsWrapper,
  techStackWrapper,
  initiativeWrapper,
  hiringSectionHeader,
  whatYouExpectFromUsSectionTitle,
  headerSection,
  buttonSection,
  positionButton,
  programmeButtonDiv,
  programmeButton,
  cultureSection,
  cultureSectionInner,
  workForUsSection,
  workForUsSectionTitle,
  workForUsSectionColumnOne,
  workForUsSectionMain,
  whatYouExpectFromUsColumn,
  workForUsSectionColumn,
  whatYouExpectSection,
  workForUsSectionContainer,
  ourHiringProcessInner,
  ourHiringProcessCta,
  ourTeamSection,
  techStackSection,
  techStackSectionInner,
  techStackSectionImage,
  techStackSectionInnerColumn,
  techStackSectionImageMobile,
  expandedStackImage,
  techStackSectionInnerColumnMobile,
  techStackSectionDescMobile,
  readMoreDiv,
  readMoreButton,
  initiativeSection,
  initiativeSectionInner,
  ourInitiativeRow,
  ourInitiativeColumn,
  sheCodeAfricaSection,
  memberCarousel,
  testimonialAuthor,
  testimonialAvatar,
  testimonialAuthorName,
  testimonialAuthorJobTitle,
  testimonialAuthorNameMobile,
  testimonialAuthorCountry,
  careerHeadingWrapper,
  careerLink,
  workForUsSectionWrapper
}
  from '../styles/careers.module.css'
import {
  quoteStyleLeft,
  quoteStyleRight
} from '../styles/internship.module.css'
import {
  pageWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query($first: Int) {
    wordPress {
      teamMembers(first: $first) {
        edges {
          node {
            id
            status
            slug
            membersFieldGroup {
              memberFirstName
              memberSurname
              memberJobTitle
              memberCountry
              memberGender
              memberPosition
              memberQuote
              memberJobGroup
              memberTestimonial
              isIntern
              isAlumni
              hasTestimonial
              memberAvatar {
                sourceUrl
              }
            }
          }
        }
      }
      pages(where: {name: "Careers"}) {
        edges {
          node {
            headerSection {
              heroHeading
            }
            careersFieldGroup {
              cultureSectionCta
              cultureSectionHeader
              cultureSectionText
              workForUsSectionHeader
              leftWorkForUsSectionReasons {
                reasonHeader
                reasonText
              }
               rightWorkForUsSectionReasons {
                reasonHeader
                reasonText
              }
              whatYouExpectFromUsSectionHeader
              whatYouExpectFromUsSectionRight {
                reasonText
              }
               whatYouExpectFromUsSectionLeft {
                reasonText
              }
              ourHiringProcessHeader
              ourHiringProcess {
                ourHiringProcessList
              }
              ourTeamSectionHeader
              ourTeamSectionText
              whatTheTeamSaysText
              ourTechStackDesc
              ourTechStackHeader
              ourTechStackImage {
                sourceUrl
              }
              ourInitiativesSectionHeader
              ourInitiativesSectionDesc
              currentInitiativeHeader
              ourInitiativeImage {
                sourceUrl
              }
              currentInitiatives {
                currentInitiativeHeader
                currentInitiativeDescription
              }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const CareersPage = ({ data, pageContext }) => {
  const teamMembers = data.wordPress.teamMembers.edges
  const careerData = data.wordPress.pages.edges[0].node
  const workForUsSectionReasonsLeft = careerData.careersFieldGroup.leftWorkForUsSectionReasons
  const workForUsSectionReasonsRight = careerData.careersFieldGroup.rightWorkForUsSectionReasons
  const whatYouExpectFromUsDataLeft = careerData.careersFieldGroup.whatYouExpectFromUsSectionLeft
  const whatYouExpectFromUsDataRight = careerData.careersFieldGroup.whatYouExpectFromUsSectionRight
  const ourHiringProcessData = careerData.careersFieldGroup.ourHiringProcess
  const currentInitiativesData = careerData.careersFieldGroup.currentInitiatives
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const [activeCaret, setActiveCaret] = useState(true)
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug

  let teamTestimonial = []
  teamMembers.map((item) => {
    if (item.node.membersFieldGroup.memberTestimonial !== null && item.node.membersFieldGroup.hasTestimonial === true) {
      teamTestimonial.push(item.node.membersFieldGroup)
    }
  })

  const readMore = () => {
    const techStackMobile = document.getElementById('techStackMobile')
    const moreText = document.getElementById('techStackMoreText')
    const readMoreDiv = document.getElementById('readMoreDiv')
    const readMoreBtn = document.getElementById('readMoreBtn')
    const expandedStackImage = document.getElementById('expandedStackImage')
    const collapsedStackImage = document.getElementById('collapsedStackImage')

    if (techStackMobile.style.display === 'none') {
      techStackMobile.style.display = 'block'
      readMoreDiv.style.height = '236px'
      readMoreBtn.innerHTML = 'See more tech and read about approach'
      moreText.style.display = 'none'
      setActiveCaret(true)
      expandedStackImage.style.display = 'none'
      collapsedStackImage.style.display = 'block'
    } else {
      collapsedStackImage.style.display = 'none'
      techStackMobile.style.display = 'none'
      moreText.style.marginBottom = '42px'
      readMoreDiv.style.height = '0px'
      readMoreBtn.innerHTML = 'Read less'
      setActiveCaret(false)
      expandedStackImage.style.display = 'block'
      moreText.style.display = 'block'
    }
  }

  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ Career Opportunities'
        title='Careers'
      />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Careers'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        <div className={careerHeadingWrapper}>
          <h1>{careerData.headerSection.heroHeading}</h1>
          <div className={buttonSection}>
            <div>
              <a
                className={positionButton}
                href='https://deimoscloud.bamboohr.com/jobs/'
                target='_blank'>
                <img
                  alt='link icon'
                  src={LinkIcon} />
                <span>See open positions</span>
              </a>
            </div>
            <div className={programmeButtonDiv}>
              <Link
                className={programmeButton}
                to='/careers/internship'>
                See our Internship Programme
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={cultureSection}>
        <div className={cultureSectionInner}>
          <h2>{careerData.careersFieldGroup.cultureSectionHeader}</h2>
          <span>{parse(careerData.careersFieldGroup.cultureSectionText)}</span>
          <Link to='/careers/culture-and-values'>
            {careerData.careersFieldGroup.cultureSectionCta}
          </Link>
        </div>
      </div>
      <div className={workForUsSectionWrapper}>
        <div className={workForUsSectionContainer}>
          <div className={workForUsSectionTitle}>
            <h2>{careerData.careersFieldGroup.workForUsSectionHeader}</h2>
          </div>
          <div>
            {
              workForUsSectionReasonsLeft.map((item, index) => {
                return (
                  <div
                    className={composeClasses(workForUsSectionColumn, workForUsSectionColumnOne)}
                    key={index.toString()}>
                    <h5>{item.reasonHeader}</h5>
                    <span>{parse(item.reasonText)}</span>
                  </div>
                )
              })
            }
          </div>
          <div>
            {
              workForUsSectionReasonsRight.map((item, index) => {
                return (
                  <div
                    className={workForUsSectionColumn}
                    key={index.toString()}>
                    <h5>{item.reasonHeader}</h5>
                    <span>{parse(item.reasonText)}</span>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
      <div className={workForUsSectionMain}>
        <div className={whatYouExpectSection}>
          <div className={whatYouExpectFromUsSectionTitle}>
            <h2>{careerData.careersFieldGroup.whatYouExpectFromUsSectionHeader}</h2>
          </div>
          <div>
            {
              whatYouExpectFromUsDataLeft.map((item, index) => {
                return (
                  <div
                    className={composeClasses(workForUsSectionColumn, workForUsSectionColumnOne, whatYouExpectFromUsColumn)}
                    key={index.toString()}>
                    <img src={checkMark} />
                    <p>{item.reasonText}</p>
                  </div>
                )
              })
            }
          </div>
          <div>
            {
              whatYouExpectFromUsDataRight.map((item, index) => {
                return (
                  <div
                    className={composeClasses(workForUsSectionColumn, whatYouExpectFromUsColumn)}
                    key={index.toString()}>
                    <img src={checkMark} />
                    <p>{item.reasonText}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={workForUsWrapper}>
        <div className={workForUsSection}>
          <div className={hiringSectionHeader}>
            <h2>{careerData.careersFieldGroup.ourHiringProcessHeader}</h2>
          </div>
          <div className={ourHiringProcessInner}>
            <ol>
              {
                ourHiringProcessData.map((item, index) => {
                  return (
                    <li
                      key={index.toString()}>
                      {item.ourHiringProcessList}
                    </li>
                  )
                })
              }
            </ol>
          </div>
        </div>
        <div className={composeClasses(sharedStyles.verticalContainer, ourHiringProcessCta)}>
          <a
            className={positionButton}
            href='https://deimoscloud.bamboohr.com/jobs/'
            target='_blank'
          >
            <img
              alt='link icon'
              src={LinkIcon} />
            <span>See open positions</span>
          </a>
        </div>
      </div>
      <div className={composeClasses(sharedStyles.verticalContainer, ourTeamSection)}>
        <div className={composeClasses(sharedStyles.verticalContainerInner, headerSection)}>
          <h2>{careerData.careersFieldGroup.ourTeamSectionHeader}</h2>
          <span className={careerLink}>{parse(careerData.careersFieldGroup.ourTeamSectionText)}</span>
          <h4>{careerData.careersFieldGroup.whatTheTeamSaysText}</h4>
          <CarouselComponent
            items={teamTestimonial}
            itemsToShow={3}
            render={(item, index) => {
              const { memberJobTitle, memberTestimonial, memberSurname, memberFirstName, memberAvatar } = item
              return (
                <div
                  className={memberCarousel}
                  key={index}
                  style={isMobile && teamTestimonial.length < 3 ? { maxWidth: '100%' } : ''}
                >
                  <div className={testimonialAuthor}>
                    <img
                      className={testimonialAvatar}
                      src={memberAvatar && memberAvatar.sourceUrl}
                    />
                    <div>
                      <span className={testimonialAuthorName}>{`${memberFirstName && memberFirstName} ${memberSurname && memberSurname}`}</span>
                      <p className={testimonialAuthorJobTitle}>{memberJobTitle}</p>
                      <span className={testimonialAuthorNameMobile}>{`${memberFirstName && memberFirstName} ${memberSurname && memberSurname}`} </span>
                      <br className={testimonialAuthorNameMobile} />
                      <span className={testimonialAuthorCountry}>{memberJobTitle}</span>
                    </div>
                  </div>
                  <img
                    className={quoteStyleLeft}
                    src={quoteLeft}
                  />
                  <span>{parse(memberTestimonial)}</span>
                  <img
                    className={quoteStyleRight}
                    src={quoteLeft}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
      <div className={techStackWrapper}>
        <div className={techStackSection}>
          <h2>{careerData.careersFieldGroup.ourTechStackHeader}</h2>
        </div>
        {/* Desktop */}
        <div className={techStackSectionInner}>
          <div className={composeClasses(techStackSectionImage, techStackSectionInnerColumn)}>
            <img
              alt='Deimos Tech Stack'
              src={careerData.careersFieldGroup.ourTechStackImage.sourceUrl} />
          </div>
          <div className={techStackSectionInnerColumn}>
            <span>{parse(careerData.careersFieldGroup.ourTechStackDesc)}</span>
          </div>
        </div>
        {/* Mobile */}
        <div className={composeClasses(techStackSectionImageMobile, techStackSectionInnerColumn)}>
          <img
            alt='Deimos Tech Stack Mobile'
            id='collapsedStackImage'
            src='https://admin.deimos.io/wp-content/uploads/mobile-tech-collapsed.svg' />
          <img
            alt='Deimos Tech Stack Mobile'
            className={expandedStackImage}
            id='expandedStackImage'
            src='https://admin.deimos.io/wp-content/uploads/techstack-mobile.svg' />
        </div>
        <div className={techStackSectionInnerColumnMobile}>
          <span id='techStackMobile' />
          <span
            className={techStackSectionDescMobile}
            id='techStackMoreText'>{parse(careerData.careersFieldGroup.ourTechStackDesc)}</span>
        </div>
        <div
          className={readMoreDiv}
          id='readMoreDiv'>
          <button
            className={readMoreButton}
            id='readMoreBtn'
            onClick={readMore}> See more tech and read about approach </button>
          <img
            alt='direction icon'
            src={activeCaret ? CaretDown : CaretUp}
          />
        </div>
      </div>
      <div className={initiativeWrapper}>
        <div className={initiativeSection}>
          <div className={initiativeSectionInner}>
            <h2>{careerData.careersFieldGroup.ourInitiativesSectionHeader}</h2>
            <span>{parse(careerData.careersFieldGroup.ourInitiativesSectionDesc)}</span>
          </div>
        </div>

        <div className={ourInitiativeRow}>
          <div className={ourInitiativeColumn}>
            <h4>{parse(careerData.careersFieldGroup.currentInitiativeHeader)}</h4>
            {
              currentInitiativesData && currentInitiativesData.map((item, index) => {
                return (
                  <div
                    className={sheCodeAfricaSection}
                    key={index.toString()}>
                    <h5>{item.currentInitiativeHeader}</h5>
                    <span>{parse(item.currentInitiativeDescription)}</span>
                  </div>
                )
              })
            }
          </div>
          <div className={ourInitiativeColumn}>
            <img src={careerData.careersFieldGroup.ourInitiativeImage.sourceUrl} />
          </div>
        </div>
      </div>

      <SocialLinks
        hasWhiteBackground
        socialLinks={footerData.socialLinks} />
    </>
  )
}

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CareersPage
