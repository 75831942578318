// extracted by mini-css-extract-plugin
export var headerSection = "careers-module--headerSection--Jh81x";
export var cultureSectionInner = "careers-module--cultureSectionInner--3EMDb";
export var careerLink = "careers-module--careerLink--yOhqL";
export var careerHeadingWrapper = "careers-module--careerHeadingWrapper--MAu2V";
export var buttonSection = "careers-module--buttonSection--Dsw9W";
export var programmeButton = "careers-module--programmeButton--xhtoo";
export var positionButton = "careers-module--positionButton--aYbRO";
export var cultureSection = "careers-module--cultureSection--vkryG";
export var ourTeamSection = "careers-module--ourTeamSection--jIzNY";
export var initiativeSection = "careers-module--initiativeSection--ibSmd";
export var workForUsSection = "careers-module--workForUsSection--F6Oyv";
export var workForUsSectionContainer = "careers-module--workForUsSectionContainer--0n7YL";
export var whatYouExpectSection = "careers-module--whatYouExpectSection---cB3c";
export var whatYouExpectFromUsColumn = "careers-module--whatYouExpectFromUsColumn--foMMl";
export var workForUsSectionColumn = "careers-module--workForUsSectionColumn--KvynW";
export var workForUsSectionTitle = "careers-module--workForUsSectionTitle--MYnoI";
export var whatYouExpectFromUsSectionTitle = "careers-module--whatYouExpectFromUsSectionTitle--M4iE0";
export var workForUsSectionColumnOne = "careers-module--workForUsSectionColumnOne--B8fbb";
export var workForUsSectionMain = "careers-module--workForUsSectionMain--zJs1e";
export var hiringSectionHeader = "careers-module--hiringSectionHeader--pNYD8";
export var workForUsWrapper = "careers-module--workForUsWrapper--NSnF8";
export var techStackWrapper = "careers-module--techStackWrapper--DxA8j";
export var initiativeWrapper = "careers-module--initiativeWrapper--561OL";
export var ourHiringProcessInner = "careers-module--ourHiringProcessInner--VGfHZ";
export var ourHiringProcessCta = "careers-module--ourHiringProcessCta--N9dPk";
export var memberCarousel = "careers-module--memberCarousel--3uPN5";
export var testimonialAuthor = "careers-module--testimonialAuthor--38dlh";
export var testimonialAuthorName = "careers-module--testimonialAuthorName--gq93L";
export var testimonialAuthorJobTitle = "careers-module--testimonialAuthorJobTitle--tLChh";
export var testimonialAuthorCountry = "careers-module--testimonialAuthorCountry--ZE2GW";
export var testimonialIndex = "careers-module--testimonialIndex--dCU6j";
export var testimonialAvatar = "careers-module--testimonialAvatar--9ua62";
export var techStackSection = "careers-module--techStackSection--OTVU3";
export var techStackSectionInner = "careers-module--techStackSectionInner--jWtFt";
export var techStackSectionInnerColumn = "careers-module--techStackSectionInnerColumn--wLv26";
export var techStackSectionImage = "careers-module--techStackSectionImage--YeJzQ";
export var ourInitiativeRow = "careers-module--ourInitiativeRow--3xy8T";
export var techStackSectionImageMobile = "careers-module--techStackSectionImageMobile--g4JzY";
export var techStackSectionInnerColumnMobile = "careers-module--techStackSectionInnerColumnMobile--f6qY1";
export var readMoreButton = "careers-module--readMoreButton--54mpm";
export var expandedStackImage = "careers-module--expandedStackImage--+xuaq";
export var readMoreDiv = "careers-module--readMoreDiv--jFGRR";
export var testimonialAuthorNameMobile = "careers-module--testimonialAuthorNameMobile--HUBGB";
export var initiativeSectionInner = "careers-module--initiativeSectionInner--HzFWW";
export var workForUsSectionWrapper = "careers-module--workForUsSectionWrapper--iRhFw";
export var ourInitiativeColumn = "careers-module--ourInitiativeColumn--QS5P9";
export var sheCodeAfricaSection = "careers-module--sheCodeAfricaSection--xBNVA";
export var techStackSectionDescMobile = "careers-module--techStackSectionDescMobile--OIClZ";
export var programmeButtonDiv = "careers-module--programmeButtonDiv--nRmgO";